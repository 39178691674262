import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectAccountStyle } from './selectAccountStyle';
import Constants from '../utils/constants';
import { concat as _concat } from 'lodash';
import { Grid, Select, MenuItem, InputLabel } from '@material-ui/core';
import mapStateToProps from '../actions/stateToProps';
import { withSnackbar } from 'notistack';
import './selectAccount.css';
import { AccountHandler } from '../api';

let SESSION_KEYS = Constants.SESSION_KEYS;
class SelectAccount extends React.Component {
    constructor(props) {
        super(props);
        this._handler = new AccountHandler(this);
        this.handleFieldChange = this.handleFieldChange.bind(this)
    }

    state = {
        roles: {
            owner: false,
            admin: false,
            editor: false,
            viewer: false
        },
        accounts: [Constants.DEFAULT_ACCOUNT],
        selectedAccount: Constants.DEFAULT_ACCOUNT,
        openOverlay: false
    }

    componentDidMount() {
        this.handleGetAccounts(false);
    }

    handleGetAccounts = async (unset=false) => {
        let _accountLineup = [];
        let _roles = this.state.roles;
        let defaultAccount = Constants.DEFAULT_ACCOUNT;
        let requested = defaultAccount;
        // let retrieveFrom = Constants.URLs.ACCOUNTS;
        this.setState({ openOverlay: true });
        this._handler.getAccountsCached(unset).then((accounts) => {
            _accountLineup = _concat(defaultAccount, accounts);
            let active = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT);
            if (active !== undefined && active !== '') {
                requested = accounts.filter((item) => {
                    return item._id === active;
                })[0];
            }
            // console.log('identified object by the supplied param is:' + JSON.stringify(requested));
            if (!requested) {
                requested = defaultAccount;
                this.props.enqueueSnackbar('Requested account is not accessible. Please chose a valid account.', {
                    variant: 'warning'
                });
            } else {
                if (requested && requested.hasOwnProperty('curr') && requested.curr !== '') {
                    sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_CURR, requested.curr);
                }
                if (requested && requested.hasOwnProperty('type') && requested.type !== '') {
                    sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE, requested.type);
                }
                _roles.owner = requested.isOwner || false;
                _roles.admin = requested.isAdmin || false;
                _roles.editor = requested.isEditor || false;
                _roles.viewer = requested.isViewer || false;
                _roles.any = requested.isOwner || requested.isAdmin || requested.isEditor || requested.isViewer;
            }
            
            this.setState({
                openOverlay: false,
                roles: _roles,
                selectedAccount: requested,
                accounts: _accountLineup
            })
            this.props.onReady(_accountLineup, requested, _roles);
        })
    }

    handleFieldChange(e) {
        if (e.target.value === 'unknown') {
            //do nothing
            return;
        }
        sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT, e.target.value);
        let account = this.state.accounts.filter((item) => {
            return item._id === e.target.value;
        })[0];
        if (account && account.hasOwnProperty('curr') && account.curr !== '') {
            sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_CURR, account.curr);
        }
        if (account && account.hasOwnProperty('type') && account.type !== '') {
            sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE, account.type);
        }
        let _roles = this.state.roles;
        _roles.owner = account.isOwner || false;
        _roles.admin = account.isAdmin || false;
        _roles.editor = account.isEditor || false;
        _roles.viewer = account.isViewer || false;
        _roles.any = account.isOwner || account.isAdmin || account.isEditor || account.isViewer;
        this.setState({
            selectedAccount: account,
            roles: _roles
        });
        this.props.onChange(this.state.accounts, account, _roles);
    }

    render() {
        const { classes } = this.props;
        return (
            // <Grid container id="trans-account-select">
                <Grid item lg={5} md={5} sm={12} xs={12}>
                    <div className={classes.selectAccountContainer}>
                        <InputLabel>Select Account:</InputLabel>
                        <Select
                            name="select-account"
                            data-cy="ddTranSelectAccount"
                            value={this.state.selectedAccount._id}
                            onChange={this.handleFieldChange}
                            className={'trans-account-select-width'}>
                            {this.state.accounts.map((account, index) => {
                                return (
                                    <MenuItem
                                        key={`account-${account._id}`}
                                        value={account._id}
                                        code={account.code}>
                                        <img alt="" style={{width: '20px'}} src={`/assets/img/accounts/icons/${account.type}.png`}></img>
                                        {Constants.getAccountName(account)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </Grid>
            // </Grid>
        )
    }
}

SelectAccount.propTypes = {
    classes: PropTypes.object.isRequired,
    onReady: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(SelectAccountStyle)(SelectAccount)));
